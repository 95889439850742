import React from 'react';
import s from './Collapse.module.scss';
import arrow_up from '../images/icons/up-arrow-blue.svg';
import arrow_down from '../images/icons/down-arrow-blue.svg';


class Collapse extends React.Component {

    state = {
        collapse: false,
    }
    componentDidMount = () => {
       document.addEventListener('click', this.handleClick, false)
    }

    componentWillUnmount = () => {
        document.removeEventListener('click', this.handleClick, false)
    }

    handleCollapse = (e) => {
        e.preventDefault();
        this.setState({
            collapse: !this.state.collapse
        })
    }

    handleClick = (e) =>{
        if(!this.node.contains(e.target)){
            this.setState({
                collapse:false
            })
        }
    }

    render() {
        const {collapse} = this.state;
        const { children, title, style } = this.props;

        return (
            <div className={s.collapseContainer} ref={node => this.node = node}>
                <div onClick={this.handleCollapse} className={s.collapseBut} onKeyDown={this.handleCollapse} role="button" tabIndex="0" >
                    <p className={s.title}>{title}</p>
                    {(collapse) ? <img className={s.arrowIcon} src={arrow_up} alt='flecha hacia arriba'/> : <img className={s.arrowIcon} src={arrow_down} alt='flecha hacia abajo'/>}
                </div>
                <div  className={(collapse) ? `${s.openElement} collapse` : s.collapseElement} style={style} >
                    <div className={s.collapseMenu} dangerouslySetInnerHTML={{__html: children}}>
                    </div>
                </div>
            </div>
        );
  }
}

export default Collapse;
