import React from "react";
import "../style/style.module.scss";
import { useStaticQuery, graphql } from "gatsby";
import s from '../style/pymePage.module.scss';
import Header from '../components/Header';
import Graphic from '../components/Graphic';
import OurNumbers from '../components/OurNumbers';
import OurProcess from '../components/OurProcess';
import Blog from '../components/Blog';
import Media from '../components/Media';
import FaqsContainer from '../components/FaqsContainer';
import Experts from '../components/Experts';
import CTAFooter from '../components/CTAFooter';
import Footer from '../components/Footer';
import SingleCreditButton from '../components/SingleCreditButton';
import { TOP_LEVEL_PREFIX,  DESKTOP, LAPTOP, MOBILE  } from '../config.js';
import {isMobile} from 'react-device-detect';
import { TagManager, TagManagerFooter } from '../components/TagManager';
import MetaData from '../components/MetaData';
import PageView from '../components/PageView';


const PymePage = () => {
    const productType ='Pyme'
    const data = useStaticQuery(graphql` {
            gcms {
                headerInfo: headers (where: {status: PUBLISHED, landingType: Pyme}){
                    landingType
                    preTitle
                    title
                    subTitle{
                        html
                    }
                }
                checks: headers(where: {landingType: Pyme}){
                    check1{
                        html
                    }
                    check2{
                        html
                    }
                    check3{
                        html
                    }
                }
            },
            desktop: file(relativePath: { eq: "Pyme.jpg"}) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 3840) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            },
            laptop: file(relativePath: { eq: "Pyme-small.jpg"}) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1400) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            },
            mobile: file(relativePath: { eq: "Pyme-mobile.jpg"}) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 500) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }`);
        const headers = data.gcms;
        let imageDataSrc = [
            {
                ...data.mobile.childImageSharp.fluid,
                media: MOBILE,
            },
        ];
        if (!isMobile) {
            imageDataSrc = [
                {
                    ...data.desktop.childImageSharp.fluid,
                    media: DESKTOP,
                },
                {
                    ...data.laptop.childImageSharp.fluid,
                    media: LAPTOP,
                },
            ];
        }
    return(
      <div>
            <MetaData/>
            <TagManager/>
            <PageView/>
            <Header preTitle={headers.headerInfo[0].preTitle} title={headers.headerInfo[0].title}
                subTitle={headers.headerInfo[0].subTitle.html} className={s.headerContainer}
                checks={headers.checks} backgrounds={imageDataSrc}
                subLanding={true}
            >
                <SingleCreditButton creditText='SIMULÁ TU CUOTA' href={`${TOP_LEVEL_PREFIX}/app/hd/completa-tus-datos/mi-prestamo/?destination=PY`}/>
            </Header>
            <Graphic/>
            <OurNumbers/>
            <OurProcess/>
            <Media/>
            <Blog/>
            <FaqsContainer productType={productType} showAll={true}/>
            <Experts/>
            <CTAFooter>
                Averiguá sin compromiso a qué crédito podrías acceder
            </CTAFooter>
            <Footer productType={productType}/>
            <TagManagerFooter/>
      </div>
    )
}

export default PymePage
