import React from 'react';
import s from './FaqsContainer.module.scss';
import Collapse from './Collapse';
import { useStaticQuery, graphql } from "gatsby";


export const FaqsContainer = ({ productType, showAll=false }) => {
    const faqs = useStaticQuery(graphql`
    {
        gcms { 
            faqs (where: {status: PUBLISHED}) {
                id
                title
                productType
                description { 
                    html
                }
            }
        }
      }`
  ).gcms.faqs;


    return(
        <div className={s.faqsContainer} id='preguntas-frecuentes'>
            <h3 className={s.faqsTitle}>Preguntas frecuentes</h3>
            <div className={s.collapsesContainer} >
                {faqs.map((faq, index) =>
                    (faq.productType === productType || (showAll && faq.productType === "All")) && 
                    <Collapse title={faq.title} key={index} style={(index === faqs.length -1) ? {borderBottom: '0px'} : null}>
                        {faq.description.html}
                    </Collapse>
                )}
            </div>
        </div>
    );
}

export default FaqsContainer;
