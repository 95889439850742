import React from "react";
import Button from "./Button.js";
import GraphicDesk from '../images/1366.svg';
import GraphicMobile from '../images/Mobile.svg';
import s from './Graphic.module.scss';
import {isMobile} from 'react-device-detect';
import { navigate } from '@reach/router';


const Graphic = () => {
  return (
    <div className={s.container}>
        <p className={s.title}>Con AGILIS accedés a un monto mayor,<br/>¡pagando las cuotas más bajas!</p>
        <div className={s.body}>
            <div className={s.divGraphic}>
                <img className={s.graphic} src={!isMobile ? GraphicDesk : GraphicMobile} alt="Grafico de cuotas"/>
            </div>
            <div className={s.info}>
                <div className={s.text}>
                    <p className={s.titleText}>Crédito con Garantía: ¿Qué es?</p>
                    <p>Es la mejor forma de acceder a un financiamiento de calidad con las tasas más bajas, los plazos más largos y las cuotas más accesibles.</p>
                    <p>Al utilizar un bien (un vehículo o un inmueble) como respaldo, aumenta tu compromiso y por lo tanto mejoran mucho las condiciones de tu préstamo.</p>
                    <p>¡Transformá tus activos en un crédito de calidad para concretar tus proyectos, abrir tu negocio o cancelar tus deudas!</p>
                </div>
                <Button onClick = {() => navigate('#header')} text={"SIMULÁ TU CUOTA"}/>
            </div>
        </div>
    </div>
  )
}

export default Graphic
